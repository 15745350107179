import React from "react";
import Avatar from "@mui/material/Avatar";
import "./Testimonials.css";
import Ellipse34 from "../../../assets/images/Ellipse34.png";

const Testimonials = () => {
  return (
    <div className="app__testimonials-wrapper">
      <div className="app__testimonials-content">
        <h1 className="heading app__testimonials-header">Testimonials</h1>

        <Avatar
          alt="Remy Sharp"
          src={Ellipse34}
          className="app__testimonials-avatar"
          sx={{ width: 70, height: 70 }}
        />
        <p className="paragraph app__testimonials-paragraph">
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis enim velit mollit. Exercitation
          veniam consequat sunt nostrud amet.
        </p>
        <h5 className="paragraph app__testimonials-name">DOE ABOSEDE</h5>
      </div>
    </div>
  );
};

export default Testimonials;
