import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Navbar from "../../components/Navbar/Navbar";
import HeaderComponent from "../../components/Header/HeaderComponent";
import Footer from "../../components/Footer/Footer";
import "./Lifestyleinformation.css";

const Lifestyleinformation = () => {
  const handleChange = () => {};

  return (
    <div className="app__lifestyleinfo-container">
      <Navbar />
      <HeaderComponent
        header="Lifestyle Information"
        paragraph="Tell us about you"
      />
      <div className="app__lifestyleinfo-content">
        <div className="app__lifestyleinfo-header">
          <p className="app__lifestyleinfo-paragraph"> Step 2</p>
          <h5 className="app__lifestyleinfo-acctInfo">Personal Information</h5>
        </div>

        <div className="app__wrapper-header-form">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "45ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="app__input">
              <TextField
                id="outlined-name"
                label="Firstname"
                required
                // value={firstName}
                onChange={handleChange}
              />
              <TextField
                id="outlined-name"
                label="Lastname"
                required
                // value={lastName}
                onChange={handleChange}
              />
            </div>
            <div className="app__input">
              <TextField
                id="outlined-name"
                label="Email"
                required
                // value={email}
                onChange={handleChange}
              />
              <TextField
                id="outlined-name"
                label="Phone Number"
                required
                // value={phoneNumber}
                onChange={handleChange}
              />
            </div>
          </Box>
          <button type="button" className="app__lifestyleinfo-updatebtn">
            Save Personal Information
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Lifestyleinformation;
