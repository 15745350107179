import "./Header.scss";

const Header = () => {
  return (
    <div className="app__header--bg">
      <div className="app__header">
        <div className="app__header--content">
          <div className="app__header-text">
            <h1 className="app__header-title">
              {" "}
              Find Roommates & Rooms Today.
            </h1>
            <p className="app__header-paragraph">
              {" "}
              Easily search and Appartments of your choice for rent accross
              Nigeria
            </p>
          </div>
          <div className="app__appartment-btn">
            <button type="button" className="app__appartment-app btn-app">
              Find an Appartment{" "}
            </button>
            <button type="button" className="app__appartment-app btn-app">
              Find a Roommate{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
