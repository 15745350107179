import React from "react";
import "./App.scss";
import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import Findroom from "./components/Homepage/Findroom/Findroom";
import Divider from "./components/Base/Divider/Divider";
import Whyus from "./components/Homepage/Whyus/Whyus";
import Testimonials from "./components/Homepage/Testimonials/Testimonials";
import Footer from "./components/Footer/Footer";
import Findroomacrosscities from "./components/Homepage/Findroomacrosscities/Findroomacrosscities";

function App() {
  return (
    <div className="App">
      {/* <Login /> */}
      <Navbar />
      <Header />
      <Findroom />
      <Divider text="Renting your first home shouldn't empty your pocket" />
      <Whyus />
      <Divider text="A Perfect Roommate finder across Nigeria." />
      <Findroomacrosscities />
      <Divider text="Digsshare is free to list, search and communicate." />
      <Testimonials />
      <Divider text="A Perfect Roommate finder across Nigeria" />
      <Footer />
    </div>
  );
}

export default App;
