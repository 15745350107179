import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Navbar from "../../components/Navbar/Navbar";
import HeaderComponent from "../../components/Header/HeaderComponent";
import Footer from "../../components/Footer/Footer";

import "./Personalinfo.css";

const Personalinformation = () => {
  const handleChange = () => {};

  return (
    <div className="app__personalinfo-container">
      <Navbar />
      <HeaderComponent
        header="Personal Information"
        paragraph="Tell us about you"
      />
      <div className="app__personalinfo-content">
        <div className="app__personalinfo-header">
          <p className="app__personalinfo-paragraph"> Step 2</p>
          <h5 className="app__personalinfo-acctInfo">Personal Information</h5>
        </div>

        <div className="app__wrapper-header-form">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "45ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="app__input">
              <TextField
                id="outlined-name"
                label="First name"
                required
                // value={firstName}
                onChange={handleChange}
              />
              <TextField
                id="outlined-name"
                label="Last name"
                required
                // value={lastName}
                onChange={handleChange}
              />
            </div>
            <div className="app__input">
              <TextField
                id="outlined-name"
                label="Email"
                required
                // value={email}
                onChange={handleChange}
              />
              <TextField
                id="outlined-name"
                label="Phone Number"
                required
                // value={phoneNumber}
                onChange={handleChange}
              />
            </div>
          </Box>
          <button type="button" className="app__personalinfo-updatebtn">
            Save Personal Information
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Personalinformation;
