import "./HeaderComponent.css";

const Header = ({ header, paragraph }) => {
  return (
    <div className="app__header-component">
      <div className="app__header-text-component">
        <h1 className="app__header-title-component"> {header}</h1>
        <p className="app__header-paragraph-component"> {paragraph} </p>
      </div>
    </div>
  );
};

export default Header;
