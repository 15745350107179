import React from "react";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import BedIcon from "@mui/icons-material/Bed";
import BathroomIcon from "@mui/icons-material/Bathroom";

import "./Whyus.css";

const Whyus = () => {
  return (
    <div className="app__whyus-container">
      <h1 className="heading app__whyus-header">Why use Digsshare? </h1>
      <div className="app__whyus-wrapper">
        <div className="app__whyus-content">
          <CheckroomIcon
            fontSize="large"
            color="primary"
            className="app__icon"
          />
          <h6 className="app__whyus-easy"> Easy to Use</h6>
          <p className="paragraph app__whyus-paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            mattis in sapien pretium purus in magna bibendum. Justo, elementum
            sem quam commodo mauris. Netus at ante euismod ut nulla felis, id.
            Pellentesque odio consectetur non.
          </p>
        </div>

        <div className="app__whyus-content">
          <BedIcon fontSize="large" color="primary" className="app__icon" />
          <h6 className="app__whyus-easy"> Easy to Use</h6>
          <p className="paragraph app__whyus-paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            mattis in sapien pretium purus in magna bibendum. Justo, elementum
            sem quam commodo mauris. Netus at ante euismod ut nulla felis, id.
            Pellentesque odio consectetur non.
          </p>
        </div>

        <div className="app__whyus-content">
          <BathroomIcon
            fontSize="large"
            color="primary"
            className="app__icon"
          />
          <h6 className="app__whyus-easy"> Easy to Use</h6>
          <p className="paragraph app__whyus-paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            mattis in sapien pretium purus in magna bibendum. Justo, elementum
            sem quam commodo mauris. Netus at ante euismod ut nulla felis, id.
            Pellentesque odio consectetur non.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Whyus;
