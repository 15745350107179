import React from "react";
import { styled } from "@mui/material/styles";
// import Button from '@mui/material/Button';

import Navbar from "../../components/Navbar/Navbar";
import HeaderComponent from "../../components/Header/HeaderComponent";
import Footer from "../../components/Footer/Footer";
import "./Roomupload.css";

const Input = styled("input")({
  display: "none",
});

const Roomupload = () => {
  return (
    <div className="app__roomupload-container">
      <Navbar />
      <HeaderComponent header="Room Pictures" paragraph="Tell us about you" />
      <div className="app__roomupload-content">
        <div className="app__roomupload-header">
          <p className="app__roomupload-paragraph"> Step 4</p>
          <h5 className="app__roomupload-roomPicture">Room Picture</h5>
          <p className="app__roomupload-max">
            {" "}
            Upload Pictures or Videos of the Room for Rent (5MB max)
          </p>
        </div>

        <div className="app__wrapper-header-form">
          <div className="app__roomupload">
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
              />
              <p className="app__roomupload-DragAndDrop">
                {" "}
                Drag and drop files here or Click to upload
              </p>
            </label>
          </div>

          <input
            className="app__inputInfoAboutPic"
            placeholder="Brief Information about pictures"
          />
          <button type="button" className="app__roomupload-btn">
            Publish Listing
          </button>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Roomupload;
