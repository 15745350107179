import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import digsshareLogo from "../../assets/images/digsshare_logo.png";

import "./Footer.css";

const Footer = () => {

  return (
      <div className="app__footer-bg">
        <div className="app__footer app__bg section__padding" id="login">
          <div className="app__footer-links">
            <div className="app__footer-item app__footer--logocol">
              <div className="app__footer-links_logo">
                <img
                    src={digsshareLogo}
                    alt="footer_logo"
                    style={{ width: "80px", height: "auto" }}
                />
              </div>
              <p className="p__opensans">
                We are committed to making your search for home easier. Whether you
                want an apartment to yourself or you want a shared apartment.
              </p>
            </div>
            <div className="app__footer-group">
              <div className="app__footer-item">
                <div className="app__footer-links_work">
                  <h1 className="app__footer-headtext">Quick Links</h1>
                  <div className="app__footer--quicklinks">
                    <div className="">
                      <p className="p__opensans">
                        <span>Home</span>
                      </p>

                      <p className="p__opensans">
                        <span>About us</span>
                      </p>

                      <p className="p__opensans">
                        <span>Take a Tour</span>
                      </p>

                      <p className="p__opensans">
                        <span>Help</span>
                      </p>
                    </div>
                    <div className="">
                      <p className="p__opensans">
                        <span>FAQs</span>
                      </p>
                      <p className="p__opensans">
                        <span>Home</span>
                      </p>
                      <p className="p__opensans">
                        <span>Privacy Policy</span>
                      </p>
                      <p className="p__opensans">
                        <span>Terms and Conditios</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="app__footer-item">
                <div className="app__footer-links_contact">
                  <h1 className="app__footer-headtext">Contacts</h1>
                  <p className="p__opensans">
                    <a href="https://goo.gl/maps/WgRb78bCZiRsmLTL7" target="_blank" rel="noreferrer">
                      6, FUTA Road, Akure Ondo State
                    </a>
                  </p>
                  <p className="p__opensans">
                    <a href="mailto:hello@digshare.com">hello@digshare.com</a>
                  </p>
                  <p className="p__opensans">
                    <a href="tel:+2349070223427">+234 907 022 3427</a>
                  </p>
                  <p className="p__opensans">
                    <a href="tel:+2347064196590">+234 706 419 6590</a>
                  </p>

                  <div className="app__footer-links_icons">
                    <a href="https://web.facebook.com/Digsshare" target="_blank" rel="noreferrer">
                      <LinkedInIcon />
                    </a>
                    <a href="https://twitter.com/digsshare" target="_blank" rel="noreferrer">
                      <TwitterIcon />
                    </a>
                    <a href="https://www.instagram.com/digsshare/" target="_blank" rel="noreferrer">
                      <InstagramIcon />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/78839561"
                        target="_blank"
                        rel="noreferrer"
                    >
                      <FacebookIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nav">
            <div className="nav__left">
              <div className="nav__logo">
                <img src="" alt="" className="nav__logo--img nav__logo--img-1" />
                <img src="" alt="" className="nav__logo--img nav__logo--img-2" />
                <img src="" alt="" className="nav__logo--img nav__logo--img-3" />
              </div>
            </div>
            <div className="nav__right"></div>
          </div>

          <div className="footer__copyright">
            <p className="p__opensans">
              Digsshare &copy; 2022. All Rights reserved.
            </p>
          </div>
        </div>
      </div>
  );
}

export default Footer;
