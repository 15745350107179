import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Navbar from "../../components/Navbar/Navbar";
import HeaderComponent from "../../components/Header/HeaderComponent";
import Footer from "../../components/Footer/Footer";

import "./Editprofile.css";

const Editprofile = () => {
  // const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', phoneNumber: ''});

  const handleChange = () => {};

  return (
    <div className="app__editprofile-container">
      <Navbar />
      <HeaderComponent
        header="Edit Profile"
        paragraph="Easily search Roommates and Appartments of your choice for rent across Nigeria"
      />
      <div className="app__editprofile-content">
        <div className="app__editprofile-header">
          <p className="app__editprofile-paragraph"> Step 1</p>
          <h5 className="app__editprofile-acctInfo">Account Information</h5>
        </div>

        <div className="app__wrapper-header-form">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "45ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="app__input">
              <TextField
                id="outlined-name"
                label="Firstname"
                required
                // value={firstName}
                onChange={handleChange}
              />
              <TextField
                id="outlined-name"
                label="Lastname"
                required
                // value={lastName}
                onChange={handleChange}
              />
            </div>
            <div className="app__input">
              <TextField
                id="outlined-name"
                label="Email"
                required
                fullWidth
                // value={email}
                onChange={handleChange}
              />
              <TextField
                id="outlined-name"
                label="Phone Number"
                required
                fullWidth
                // value={phoneNumber}
                onChange={handleChange}
              />
            </div>
          </Box>
          <button type="button" className="app__editprofile-updatebtn">
            Update
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Editprofile;
