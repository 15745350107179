import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import Stack from "@mui/material/Stack";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import digsshareLogo from "../../assets/images/digsshare_logo.png";

import "./login.scss";


const Login = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const googleFailure = (err) => {
    console.log("env variable" + process.env.REACT_APP_GOOGLE_CLIENT_ID);
    console.log(err);
    console.log("Google sign in not successful");
  };
  const googleSuccess = async (googleData) => {
    const res = await fetch(`${baseUrl}/auth/authWithGoogle`, {
      method: "POST",
      body: JSON.stringify({
        token: googleData.tokenId,
      }),
      headers: {
        "Content-Type": "application/json",
        // "Origin": false,
      },
    });
    console.log('the res na ', res)
    console.log("env variable" + process.env.REACT_APP_GOOGLE_CLIENT_ID);
    const data = await res.json();
    console.log("Data from backend is => ", data);
    // store returned user somehow
  };

  const facebookButtonClicked = (data) => {
    console.log('data was sent \n', data);
  };

  const responseFacebook = async (response) => {
    console.log(response);
    // const jsonResponse =
    const res = await fetch(`${baseUrl}/auth/authWithFacebook`, {
      method: "POST",
      body: JSON.stringify({
        response,
      }),
      headers: {
        "Content-Type": "application/json",
        // "Origin": false,
      },
    });
    console.log("The response is ", res);
  };

  return (
    <div className="login">
      <div className="app-container-content">
        <div className="app__content">
          <Link to="/">
            <div className="app__logo">
              <img src={digsshareLogo} alt="logo" className="app__logo-img" />
            </div>
          </Link>
          <Stack spacing={2} mt={4}>
            <h4 className="app__login-header"> Welcome Back!</h4>

            {/*LOGIN WITH FACEBOOK BUTTON*/}
            <FacebookLogin
              appId="642046927059233"
              autoLoad={false}
              fields="name,email,picture"
              onClick={facebookButtonClicked}
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  className="btn login__social--btn"
                  fullWidth={true}
                  variant="outlined"
                  type="submit"
                  startIcon={<FacebookIcon />}
                  size="large"
                  onClick={renderProps.onClick}
                >
                  Sign up with Facebook
                </Button>
              )}
            />

            {/*LOGIN WITH GOOGLE BUTTON*/}
            <GoogleLogin
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
              render={(renderProps) => (
                <Button
                  className={"login__social--btn"}
                  fullWidth
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  color="secondary"
                  type="submit"
                  size="large"
                >
                  Sign up with Google
                </Button>
              )}
              onSuccess={googleSuccess}
              onFailure={googleFailure}
              cookiePolicy="single_host_origin"
            />
          </Stack>

          <Stack mt={2}>
            <h6 className="app__or">or</h6>
          </Stack>

          <Stack spacing={2} mt={2}>
            <TextField
              type="email"
              name="email"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              autoFocus={true}
              fullWidth={true}
              className={"without-padding"}
              required
            />

            <TextField
              type={showPassword ? "text" : "password"}
              name="password"
              id="outlined-basic"
              label="Password"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              fullWidth={true}
              required
              className={"without-padding"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <p className="password">Forgot Password?</p>
            <Button
              className="btn"
              fullWidth={true}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
            >
              Login
            </Button>
            <p className="app__acct">
              {" "}
              Need an account? <Link to="/signup"> Sign up for free </Link>
            </p>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Login;
