import React from "react";
import "./Divider.css";

const Divider = ({ text }) => {
  return (
    <div className="app__divider">
      <div className="app__divider-wrapper">
        <h1 className="app__divider-text">{text}</h1>
      </div>
    </div>
  );
};

export default Divider;
