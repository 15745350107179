import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import digsshareLogo from "../../assets/images/digsshare_logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import "./Navbar.css";
import React from "react";

const Navbar = () => {
  const [showNavBar, setShowNavBar] = useState(false);

  const toggleShowNavBar = () => setShowNavBar(!showNavBar);

  return (
    <div className="app__navbar">
      <div className="app__logo">
        <img src={digsshareLogo} alt="logo" className="app__logo-img" />
      </div>
      <ul
        className={
          "app__navbar-links " +
          (showNavBar ? "app__navbar--links-show" : "app__navbar-links-hide")
        }
      >
        <li> Help</li>
        <li> Take a Tour</li>
        <li>
          <Link to="/login"> Login </Link>
        </li>
        <li>
          <Link to="/signup">
            {" "}
            <Button variant="contained" color="primary">
              Register for free
            </Button>
          </Link>
        </li>
      </ul>
      <div className="app__navbar--menu" onClick={toggleShowNavBar}>
        <MenuIcon
          fontSize="large"
          color="primary"
          className="app__navbar--menu-icon"
        />
      </div>
    </div>
  );
};

export default Navbar;
