import React from "react";
import "./Findroomacrosscities.scss";
import Akure from "../../../assets/images/akure.png";
import Abeokuta from "../../../assets/images/abkt.png";
import Lagos from "../../../assets/images/lagos.png";
import Rivers from "../../../assets/images/rivers.png";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

const Findroomacrosscities = () => {
  return (
    <div className="find-cross-site">
      <div className="app__wrapper">
        <h1 className="header heading app__wrap-head">
          Find Roommates and Rooms across Nigeria
        </h1>

        <div className="app__city-wrapper">
          <div className="app__city">
            <div className="app__city-item">
              <img src={Lagos} alt="city" className="app__city-img" />
              <span className="app__city-btn btn">
                <FmdGoodOutlinedIcon
                  fontSize="small"
                  className="app-city-btn-icon"
                />
                Lagos
              </span>
            </div>
            <div className="app__city-item">
              <img src={Rivers} alt="city" className="app__city-img" />
              <span className="app__city-btn btn">
                <FmdGoodOutlinedIcon
                  fontSize="small"
                  className="app-city-btn-icon"
                />
                Rivers
              </span>
            </div>
            <div className="app__city-item">
              <img src={Akure} alt="city" className="app__city-img" />
              <span className="app__city-btn btn">
                <FmdGoodOutlinedIcon
                  fontSize="small"
                  className="app-city-btn-icon"
                />
                Akure
              </span>
            </div>
            <div className="app__city-item">
              <img src={Abeokuta} alt="city" className="app__city-img" />
              <span className="app__city-btn btn">
                <FmdGoodOutlinedIcon
                  fontSize="small"
                  className="app-city-btn-icon"
                />
                Abeokuta
              </span>
            </div>
          </div>
        </div>

        <div>
          <h1 className="heading app__city-other">Other Cities</h1>
          <div className="app__city-wrapper-content">
            <div className="app__city-wrapper-content-city">
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
            </div>

            <div className="app__city-wrapper-content-city">
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
            </div>

            <div className="app__city-wrapper-content-city">
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
            </div>

            <div className="app__city-wrapper-content-city">
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Abia State
              </p>
            </div>

            <div className="app__city-wrapper-content-city">
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Lagos State
              </p>
            </div>

            <div className="app__city-wrapper-content-city">
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
              <p className="paragraph app__city-wrapper-content-para">
                {" "}
                Ondo State
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Findroomacrosscities;
