import "./Findroom.scss";
import manAvatar from "../../../assets/images/manAvatar.jpg";

const Avatar = ({ name, location, image }) => {
  return (
    <div className="app__avatar-wrapper">
      <img src={image} alt="avatar" className="app__wrapper-img" />
      <p className="paragraph app__name"> {name} </p>
      <p className="paragraph app__location"> {location} </p>
    </div>
  );
};

const Findroom = () => {
  return (
    <div className="findroommate">
      <div className="app__wrapper">
        <div className="app__wrapper-text">
          <h1 className="app__wrapper-header"> Find your next Roommate</h1>
          <p className="app__wrapper-paragraph">
            Some amazing people are here already
          </p>
        </div>

        <div className="app__avatar">
          <Avatar
            name={"Remy Sharp"}
            image={manAvatar}
            location={"F-20 Abuja"}
          />
          <Avatar
            name={"Travis Howard"}
            image={manAvatar}
            location={"F-20 Jos"}
          />
          <Avatar
            name={"Cindy Baker"}
            image={manAvatar}
            location={"F-24 Akure"}
          />
          <Avatar
            name={"Rukkie Cuppy"}
            image={manAvatar}
            location={"F-21 Lagos"}
          />
        </div>

        <div className="app__getstarted-bg">
          <div className="app__getstarted">
            <div className="app__getstarted-wrapper">
              <button type="button" className="app__getstarted-btn">
                {" "}
                Get Started{" "}
              </button>
              <p className="app__getstarted-text">
                {" "}
                Register today to find your next Roommate 100% free
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Findroom;
